<script setup lang="ts">
import type { IAddLimitRequest as IAddLimitPayload } from '~/interfaces/dto/player/request';
import Select from 'primevue/select';
import SelectButton from 'primevue/selectbutton';
import { selectButtonPassthrough } from '~/components/passthroughs/selectbutton';
import { useAuthService } from '~/composables/services/useAuthService';
import Button from '~/components/design-system/button.vue';
import { useSiteStore } from '~/stores/site.store';
import { useRgStore } from '~/stores/responsibleGaming.store';
import {
  rgKeyLookup,
  rgNameLookup,
  restrictionMappingKeys,
} from '~/assets/data/rgMapping';

const emits = defineEmits(['limitAdded']);
const { $t } = useNuxtApp();
const site = useSiteStore();
const rg = useRgStore();
const $authService = useAuthService();
const loader = ref(false);
const period: Ref<number> = ref(null);
const limit: Ref<number> = ref(null);
const coolingOffValid: Ref<boolean> = ref(false);
const errorMessage = ref('');
const step = ref(0);
const excluStep = ref(0);
const userLocks = ref(rg.userLocks);
const userLimits = ref(rg.userLimits);
const activeCoolOff = ref<boolean>(false);
const activeExclusion = ref<boolean>(false);
const periodOptions = [
  { text: $t('days'), value: 2 },
  { text: $t('weeks'), value: 3 },
  { text: $t('months'), value: 4 },
];

const exclusionOptions = ref(rg.exclusions);
const exclusion = ref<string>('');
const coolOffErrorMessage = ref<number>();
const selfExclusionErrorMessage = ref<number>();
const coolingOffRm = ref(rgKeyLookup.get(restrictionMappingKeys.coolingOff));

function formatPeriod() {
  var formattedPeriod = periodOptions.find(
    (item) => item.value === period.value,
  ).text;
  if (limit.value === 1) {
    formattedPeriod = formattedPeriod.slice(0, -1);
  }
  return formattedPeriod;
}

const displayOpt = () => {
  switch (period.value) {
    case 2:
      return coolingOffRm.value.durationOptions.days;
    case 3:
      return coolingOffRm.value.durationOptions.weeks;
    case 4:
      return coolingOffRm.value.durationOptions.months;
    default:
      break;
  }
};

const setDefaults = () => {
  if (rg.currentCoolOffThresholdType) {
    period.value = rg.currentCoolOffThresholdType;
    activeCoolOff.value = true;
  }
  if (rg.currentCoolOffThreshold) {
    limit.value = rg.currentCoolOffThreshold;
  }
  if (rg.exclusionReason) {
    exclusion.value = rg.exclusionReason;
    activeExclusion.value = true;
  }
};

const coolOffValid = () => {
  if (period.value !== null && limit.value !== null) {
    coolingOffValid.value = true;
  } else {
    coolingOffValid.value = false;
  }
};
const submitSelfExclusion = async () => {
  const selfExclusion = rgNameLookup.get(exclusion.value);
  const restrictionDetails = useGetRestrictionObject(
    selfExclusion.mappingCode,
    'lock',
    'brand',
  );
  const payload: IAddLimitPayload = {
    restrictionTypeId: restrictionDetails?.restrictionTypeId,
    threshold: selfExclusion.threshold,
    thresholdType: selfExclusion.thresholdType,
    restrictionReason: exclusion.value,
    internalNote: 'jpc',
  };
  await $authService.addUserLimit(payload).then((data) => {
    if (data.isSuccessful) {
      emits('limitAdded');
      site.toggleSiteNotice({
        heading: $t('success'),
        message: $t('succesfully-added-limit'),
        severity: 'success',
        enabled: true,
        timeout: 5000,
      });
      site.deactivateModal();
      site.activateModal('accountRestricted');
      excluStep.value = 0;
      $authService.getUserExtended();
    } else {
      if (data?.error) {
        coolOffErrorMessage.value = data?.error?.code;
        site.toggleSiteNotice({
          heading: $t('limit-error'),
          message: $t('limit-error-message'),
          severity: 'warning',
          enabled: true,
          timeout: 5000,
        });
      } else {
        site.toggleSiteNotice({
          heading: $t('failed'),
          message: $t('try-again'),
          severity: 'danger',
          enabled: true,
          timeout: 5000,
        });
      }
    }
  });
};
const submitCoolingOffPeriod = async () => {
  const restrictionDetails = useGetRestrictionObject(
    coolingOffRm.value.mappingCode,
    'limit',
    'brand',
  );
  const payload: IAddLimitPayload = {
    restrictionTypeId: restrictionDetails?.restrictionTypeId,
    threshold: limit.value,
    thresholdType: period.value,
    restrictionReason: restrictionDetails?.name,
    internalNote: 'jpc',
  };
  await $authService.addUserLimit(payload).then((data) => {
    if (data.isSuccessful) {
      emits('limitAdded');
      site.toggleSiteNotice({
        heading: $t('success'),
        message: $t('succesfully-added-limit'),
        severity: 'success',
        enabled: true,
        timeout: 5000,
      });
      site.deactivateModal();
      site.activateModal('accountRestricted');
      step.value = 0;
      period.value = 0;
      limit.value = 0;
      coolingOffValid.value = false;
      $authService.getUserExtended();
    } else {
      if (data?.error) {
        coolOffErrorMessage.value = data?.error?.code;
        site.toggleSiteNotice({
          heading: $t('limit-error'),
          message: $t('limit-error-message'),
          severity: 'warning',
          enabled: true,
          timeout: 5000,
        });
      } else {
        site.toggleSiteNotice({
          heading: $t('failed'),
          message: $t('try-again'),
          severity: 'danger',
          enabled: true,
          timeout: 5000,
        });
      }
    }
  });
};

watchEffect(() => {
  if (userLocks || userLimits) {
    setDefaults();
  }
});
</script>
<template>
  <LazyUserInterfaceGenericLoader
    v-if="loader"
    container
    override-positioning="!left-[100px]"
  />
  <div>
    <div v-if="$enabled('responsiblegaming.coolingoffperiod')">
      <div class="px-4 pt-2.5 flex flex-col gap-2" v-if="step === 0">
        <p class="my-0 text-base">
          <strong>{{ $t('cooling-off-period') }}</strong>
          ({{ $t('up-to-3-months') }})
        </p>

        <label class="text-sm" for="periodSelection">
          {{ $t('period') }}
        </label>
        <SelectButton
          id="periodSelection"
          v-model="period"
          :options="periodOptions"
          option-label="text"
          option-value="value"
          :allowEmpty="false"
          :pt="selectButtonPassthrough"
          :disabled="activeCoolOff"
        />
        <label class="text-sm" for="limit">{{ $t('limit') }}</label>
        <Select
          append-to="self"
          :options="displayOpt()"
          v-model:model-value="limit"
          :placeholder="$t('select')"
          @update:model-value="coolOffValid()"
          :class="!period ? 'bg-disabled text-base-disabled' : 'active'"
          :disabled="!period || activeCoolOff"
        />
        <small v-if="errorMessage" class="p-error text-xs">
          {{ $t(errorMessage) }}
        </small>
        <Button
          type="primary"
          class="w-full justify-center my-2"
          :disabled="!coolingOffValid"
          @click="step++"
        >
          {{ $t('set-your-period') }}
        </Button>
      </div>
      <div
        v-else
        class="bg-layer-1 pb-2 pt-2.5 flex flex-col gap-2 sm:rounded-xl mx-auto w-full"
      >
        <div class="flex justify-between px-4">
          <p class="my-0 font-bold">{{ $t('cooling-off-period') }}</p>
        </div>
        <hr class="dark:border-dark-900 border-light-400" />
        <div class="px-4 flex flex-col gap-2">
          <p class="mb-0">{{ $t('cooling-off-period-body-1') }}</p>
          <p class="mr-1 mb-0">
            {{ $t('your-acc-will-be-locked-for') }}
            <strong>
              {{ `${limit} ${$t(formatPeriod())}` }}
            </strong>
            ?
          </p>
          <p class="mb-0">{{ $t('cooling-off-period-body-2') }}</p>
        </div>
        <GenericError
          v-if="coolOffErrorMessage"
          state="danger"
          class="max-w-72 mx-auto mb-2"
        >
          {{ $t(`responsible-gaming-${coolOffErrorMessage}`) }}
        </GenericError>
        <div class="flex w-full justify-center px-4 mt-2 gap-3">
          <Button
            type="primary"
            class="w-full justify-center"
            @click="submitCoolingOffPeriod()"
          >
            {{ $t('confirm') }}
          </Button>
          <Button
            type="secondary"
            class="w-full justify-center"
            @click="step--"
          >
            {{ $t('cancel') }}
          </Button>
        </div>
      </div>
      <hr class="dark:border-dark-900 border-light-400 mx-2 mt-2" />
    </div>

    <div v-if="$enabled('responsiblegaming.selfexclusion')">
      <div class="px-4 pt-2 flex flex-col gap-2" v-if="excluStep === 0">
        <p class="my-0 text-base font-bold">{{ $t('self-exclusion') }}</p>
        <label class="text-sm" for="limit">
          {{ $t('set--exclusion-for') }}
        </label>
        <Select
          append-to="self"
          :options="exclusionOptions"
          v-model="exclusion"
          :placeholder="$t('select-reason')"
          :class="
            rg.accountLocked ? 'bg-disabled !text-base-disabled' : 'active'
          "
          :disabled="rg.accountLocked"
        />
        <small v-if="errorMessage" class="p-error text-xs">
          {{ $t(errorMessage) }}
        </small>

        <Button
          type="primary"
          class="w-full justify-center my-2"
          :disabled="!exclusion || activeExclusion"
          @click="excluStep++"
        >
          {{ $t('confirm') }}
        </Button>
      </div>
      <div
        v-else
        class="bg-layer-1 pb-2 pt-2.5 flex flex-col gap-2 sm:rounded-xl mx-auto w-full"
      >
        <div class="flex justify-between px-4">
          <p class="my-0 font-bold">{{ $t('self-exclusion') }}</p>
        </div>
        <hr class="dark:border-dark-900 border-light-400" />
        <div class="px-4 flex flex-col gap-2">
          <p class="mb-0">{{ $t('cooling-off-period-body-1') }}</p>
        </div>
        <GenericError
          v-if="selfExclusionErrorMessage"
          state="danger"
          class="max-w-72 mx-auto mb-2"
        >
          {{ $t(`responsible-gaming-${selfExclusionErrorMessage}`) }}
        </GenericError>
        <div class="flex w-full justify-center px-4 pt-2 gap-3">
          <Button
            type="primary"
            class="w-full justify-center"
            @click="submitSelfExclusion()"
          >
            {{ $t('confirm') }}
          </Button>
          <Button
            type="secondary"
            class="w-full justify-center"
            @click="excluStep--"
          >
            {{ $t('cancel') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
