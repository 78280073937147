 <script setup lang="ts">
import type { 
  IAddLimitRequest as IAddLimitPayload, 
  IRemoveLimitRequest 
} from '~/interfaces/dto/player/request';
import { useAuthService } from '~/composables/services/useAuthService';
import GenericError from '~/components/user-interface/generic-error.vue';
import { useFormatCurrency } from '~/composables/useFormatters';
import Select from 'primevue/select';
import Button from '~/components/design-system/button.vue';
import ProgressBar from 'primevue/progressbar';
import { useSiteStore } from '~/stores/site.store';
import { useAuthStore } from '~/stores/auth.store';
import { useRgStore } from '~/stores/responsibleGaming.store';
import type { IULResponseItem } from '~/interfaces/dto/player/response';
import { 
  rgKeyLookup, 
  restrictionMappingKeys 
} from '~/assets/data/rgMapping';
import dayjs from 'dayjs';

const emits = defineEmits(['limitAdded']);

const { $t, $enabled } = useNuxtApp();
const site = useSiteStore();
const auth = useAuthStore();
const rg = useRgStore();
const $authService = useAuthService();
const sessionErrorMessage = ref<number | string>();
const wagerErrorMessage = ref<number>();
const wagerValidation = ref<string>('');
const loader = ref<boolean>(false);
const sessionValid = ref<boolean>(true);
const sessionLimit = ref<number>(null);
const userLimits = ref(rg.userLimits);
const sessionRm = ref(rgKeyLookup.get(restrictionMappingKeys.sessionLimit));
const daily = rgKeyLookup.get(restrictionMappingKeys.dailyWagerLimit);
const weekly = rgKeyLookup.get(restrictionMappingKeys.weeklyWagerLimit);
const monthly = rgKeyLookup.get(restrictionMappingKeys.monthlyWagerLimit);

const wagerDurationOptions = ref([
  ref(daily),
  ref(weekly),
  ref(monthly)
]);

onMounted(() => {
  setDefaults();
});

const setDefaults = () => {
  if (rg.currentSessionLimit) {
    sessionLimit.value = rg.getCurrentSessionLimit
     sessionTimeout();
  }
  if (rg.currentWagerDaily) {
    daily.currentLimit = rg.currentWagerDaily 
  }
  if (rg.currentWagerWeekly) {
    weekly.currentLimit = rg.currentWagerWeekly 
  }
  if (rg.currentWagerMonthly) {
    monthly.currentLimit = rg.currentWagerMonthly 
  }
}

const sessionBtnValid = () => {
  return sessionLimit.value >= 0 ? sessionValid.value = false : sessionValid.value = true;
}

const dateDiff = (currDate) => {
  const dateSet = new Date(currDate);
  const date = dayjs();
  const difference:number = date.diff(dateSet, 'day');
  return difference
}

const isMinLockOut = (mappingCode) => {
  const restrictionDetails:IULResponseItem = useGetRestrictionObject(mappingCode, 'limit', 'user');
  if (!restrictionDetails) {
    return true
  } else {
    const editedDate = restrictionDetails?.editedDateTime;
    return dateDiff(editedDate) < 7 ? true : false
  }
}

const canUpdate = (mappingCode, newLimit) => {
  const restrictionDetails:IULResponseItem = useGetRestrictionObject(mappingCode, 'limit', 'user');
  if ((!restrictionDetails) || (newLimit <= 0) || (!newLimit)) {
    return true
  } else if (restrictionDetails?.threshold < newLimit) {
    return true
  } else {
    const editedDate = restrictionDetails?.editedDateTime;
    return dateDiff(editedDate) < 7 ? true : false
  }
}

const returnWagerTotal = (limit:number, limitName:string) => {
  const amountUsed = returnWagerProgress(limitName);
  const totalUsed:number = (amountUsed * limit) / 100;
  return useFormatCurrency(totalUsed);
}

const returnWagerProgress = (name:string) => {
  const result = wagerProgress(name);
  return result ? result : 0
}

const setWagerLimit = async(
  mapping:string, 
  amount:number
) => {
  loader.value = true;
  const restrictionDetails:IULResponseItem = useGetRestrictionObject(mapping, 'limit', 'brand');
  const payload: IAddLimitPayload = {
    restrictionTypeId: restrictionDetails?.restrictionTypeId,
    threshold: amount,
    thresholdType: 0, 
    restrictionReason: restrictionDetails?.name,
    internalNote: 'jpc',
  };
  const limitExists:boolean = getAddOrUpdate(restrictionDetails?.restrictionTypeId, 'limit');

  const setLimit = limitExists ? 
  $authService.updateUserLimit(payload) : 
  $authService.addUserLimit(payload);

  await setLimit.then((data) => {
    if (data.isSuccessful) {
      loader.value = false;
      emits('limitAdded');
      site.toggleSiteNotice({
        heading: $t('success'),
        message: $t('succesfully-added-limit'),
        severity: 'success',
        enabled: true,
        timeout: 5000
      });
      site.deactivateModal();
    } else {
      if (data?.error) {
        loader.value = false;
        wagerErrorMessage.value = data?.error?.code;
        site.toggleSiteNotice({
          heading: $t('limit-error'),
          message: $t('limit-error-message'),
          severity: 'warning',
          enabled: true,
          timeout: 5000
        });
      } else {
        loader.value = false;
        site.toggleSiteNotice({
          heading: $t('failed'),
          message: $t('try-again'),
          severity: 'danger',
          enabled: true,
          timeout: 5000
        });
      }
    }
  });
}

const removeLimit = async(mapping) => {
  loader.value = true;
  const restrictionDetails:IULResponseItem = useGetRestrictionObject(mapping, 'limit', 'brand');
  const payload: IRemoveLimitRequest = {
    accountId: auth.user_id,
    restrictionId: restrictionDetails?.restrictionTypeId,
    restrictionRemovalReason: 'user removal',
    internalNote: 'jpc',
  };
  await $authService.removeUserLimit(payload).then((data) => {
    if (data.isSuccessful) {
      loader.value = false;
      emits('limitAdded');
      site.toggleSiteNotice({
        heading: $t('success'),
        message: $t('succesfully-removed-limit'),
        severity: 'success',
        enabled: true,
        timeout: 5000
      });
      site.deactivateModal();
    } else {
      if (data?.error) {
        loader.value = false;
        sessionErrorMessage.value = data?.error?.code;
         site.toggleSiteNotice({
          heading: $t('limit-error'),
          message: $t('limit-error-message'),
          severity: 'warning',
          enabled: true,
          timeout: 5000
        });
      } else {
        loader.value = false;
        site.toggleSiteNotice({
          heading: $t('failed'),
          message: $t('try-again'),
          severity: 'danger',
          enabled: true,
          timeout: 5000
        });
      }
    }
  });
}

const setSessionLimit = async() => {
  loader.value = true;

  const restrictionDetails:IULResponseItem = useGetRestrictionObject(sessionRm.value.mappingCode, 'limit', 'brand');

  const payload: IAddLimitPayload = {
    restrictionTypeId: restrictionDetails?.restrictionTypeId,
    threshold: sessionLimit.value,
    thresholdType: sessionRm.value.thresholdType,
    restrictionReason: sessionRm.value.name,
    internalNote: 'jpc',
  };
  const limitExists:boolean = getAddOrUpdate(restrictionDetails?.restrictionTypeId, 'limit');

  const setLimit = limitExists ? 
  $authService.updateUserLimit(payload) : 
  $authService.addUserLimit(payload);
  
  await setLimit.then((data) => {
    if (data.isSuccessful) {
      loader.value = false;
      emits('limitAdded');
      site.toggleSiteNotice({
        heading: $t('success'),
        message: $t('succesfully-added-limit'),
        severity: 'success',
        enabled: true,
        timeout: 5000
      });
      sessionTimeout();
    } else {
      loader.value = false;
      if (data?.error) {
        sessionErrorMessage.value = data?.error?.code;
        site.toggleSiteNotice({
          heading: $t('limit-error'),
          message: $t('limit-error-message'),
          severity: 'warning',
          enabled: true,
          timeout: 5000
        });
      } else {
        loader.value = false;
        site.toggleSiteNotice({
          heading: $t('failed'),
          message: $t('try-again'),
          severity: 'danger',
          enabled: true,
          timeout: 5000
        });
      }
    }
  });
}

watchEffect(() => {
  if (userLimits) {
    setDefaults();
  }
});
</script>

<template>
  <LazyUserInterfaceGenericLoader v-if="loader" container />
  <div class="bg-layer-1 flex flex-col mx-auto w-full">
    <div v-if="$enabled('responsiblegaming.wagerlimit')">
      <div class="flex justify-start px-4 py-3">
        <span class="text-md my-0 text-base-priority font-bold">{{ $t('wager-limit') }}</span>
      </div>
      <hr class="dark:border-dark-900 border-light-400" />
      <div 
        v-for="(item, index) in wagerDurationOptions"
        :key="`${item.value.translation}-${index}`"
        :index="index"
      >
        <div
          class="grid grid-cols-2 px-4 py-3 gap-2"
        >
          <p class="my-0 text-sm">{{ $t('duration') }}</p>
          <p class="my-0 text-sm text-right font-bold">{{ $t(item.value.translation) }}</p>
          <p class="my-0 text-sm">{{ $t('current-limit') }}</p>
          <p
            class="my-0 text-sm font-bold text-right"
            v-html="useFormatCurrency(item.value.currentLimit)"
          />
        </div>
        <div class="mx-2 rounded-md bg-light-50 dark:bg-dark-700 pb-1">
          <div class="grid grid-cols-2 px-2 pb-1 py-2 gap-2">
            <p class="my-0 text-sm">
              {{ $t('total-accrued') }}
            </p>
            <p
              class="my-0 text-sm font-bold text-right"
              v-html="returnWagerTotal(item.value.currentLimit, item.value.name)"
            />
          </div>
          <ProgressBar 
            style="height: 6px"
            :class="['mt-1', 'mb-2', 'rounded-full', 'mx-2', 'success', 'bg-light-200', 'dark:bg-dark-900']"
            :value="returnWagerProgress(item.value.name)"
            :show-value="false"
            :pt="{
              value: {
                class: [
                  'progress-gradient', 
                  'rounded-full',
                  'transition-all',
                  'duration-200'
                ],
                style: [
                  'height: 6px'
                ]
              }
            }">
          </ProgressBar>
        </div>
        <div
          class="grid grid-cols-2 px-4 py-4 gap-2"
        >
          <p class="my-0 text-sm flex justify-center flex-col">
            {{ $t('new-wager-limit') }}
          </p>
          <input
            id="wager-limit"
            v-model="item.value.newLimit"
            type="number"
            min="1"
            step="1"
            placeholder="0"
            class="form-bg-input text-sm std-border rounded-lg pl-2 py-2"
          />
        </div>
        <div class="flex gap-4 mx-4">
          <Button
            type="secondary"
            class="w-full justify-center bg-light-50 dark:bg-dark-700"
            @click="removeLimit(item.value.mappingCode)"
            :disabled="isMinLockOut(item.value.mappingCode)"
          >
            {{ $t('clear-your-limit') }}
          </Button>
          <Button
            type="primary"
            class="w-full justify-center"
            @click="setWagerLimit(item.value.mappingCode, item.value.newLimit)"
            v-model:model-value="item.value.newLimit"
            :disabled="canUpdate(item.value.mappingCode, item.value.newLimit)" 
          >
            {{ $t('set-your-limit') }}
          </Button>
        </div>
        <hr class="dark:border-dark-900 border-light-400 my-4 mb-3" />
      </div>
      <GenericError v-if="wagerErrorMessage" state="danger" class="max-w-72 mx-auto mb-2">
        {{ $t(`responsible-gaming-${wagerErrorMessage}`) }}
      </GenericError>
      <GenericError v-if="wagerValidation" state="danger" class="max-w-72 mx-auto mb-2">
        {{ $t(`responsible-gaming-${wagerValidation}`) }}
      </GenericError>
    </div>
    <div v-if="$enabled('responsiblegaming.sessionlimit')">
      <div class="flex justify-start px-4 py-3 pt-0">
        <span class="my-0 text-md text-base-priority font-bold">{{ $t('session-limit') }}</span>
      </div>
      <hr class="dark:border-dark-900 border-light-400" />
      <div class="mx-4 flex flex-col">
        <Select
          :options="sessionRm.durationOptions.minutes"
          v-model:model-value="sessionLimit"
          :placeholder="$t('time')"
          class="my-3 py-1 text-base-priority"
          @update:model-value="sessionBtnValid()"
          :pt="{
            overlay: {
              class: 'text-base-priority bg-primary-layer std-border rounded-md shadow-md'
            } 
          }"
        />
        <GenericError v-if="sessionErrorMessage" state="danger" class="max-w-72 mx-auto mb-2">
          {{ $t(`responsible-gaming-${sessionErrorMessage}`) }}
        </GenericError>
        <div class="flex gap-4">
          <Button
            type="secondary"
            class="w-full justify-center bg-light-50 dark:bg-dark-700"
            @click="removeLimit(sessionRm.mappingCode)"
            :disabled="isMinLockOut(sessionRm.mappingCode)"
          >
           {{ $t('disable') }}
          </Button>
          <Button
            type="primary"
            class="w-full justify-center"
            @click="setSessionLimit()"
            :disabled="canUpdate(sessionRm.mappingCode, sessionLimit)" 
          >
            {{ $t('set-your-period') }}
          </Button>
        </div>
      </div>
    </div>
    <LazyUserInterfaceGenericLoader v-if="loader" container />
  </div>
</template>