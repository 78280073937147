<script setup lang="ts">
import { InformationIcon } from '~/components/design-system/icons/index';
import { useSiteStore } from '~/stores/site.store';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import { responsibleGamingTabsPassthrough } from '~/components/passthroughs/tabs';
import Exclusion from './restrictions/exclusion.vue';
import Limits from './restrictions/limits.vue';
import type { Modals } from '~/interfaces/dto/general/modals';
import { useRgStore } from '~/stores/responsibleGaming.store';
import Button from '~/components/design-system/button.vue';
import { ArrowPathIcon } from '@heroicons/vue/24/outline';

const { $t, $enabled } = useNuxtApp();
const site = useSiteStore();
const rg = useRgStore();
const tabs = ref(0);
const refreshActive = ref<boolean>(false);

const limits = computed(() => [
  $enabled('responsiblegaming.wagerlimit'),
  $enabled('responsiblegaming.sessionlimit'),
]);
const locks = computed(() => [
  $enabled('responsiblegaming.coolingoffperiod'),
  $enabled('responsiblegaming.selfexclusion'),
]);

const limitsEnabled = computed(() => limits.value.some((t) => t));
const locksEnabled = computed(() => locks.value.some((t) => t));

const defaultTab = computed(() => {
  if (
    $enabled('responsiblegaming.coolingoffperiod') ||
    $enabled('responsiblegaming.selfexclusion')
  )
    return 'Exclusion';
  if (limitsEnabled.value) return 'Limits';
});

function openInfo(modalName: Modals) {
  site.activateModal(`rG${modalName}Message`);
}

const limitsUpdated =  async() => {
 await useCheckRestrictionsService().finally(() => {
  if (rg.getCurrentSessionLimit !== null) {
    sessionTimeout();
  }
 })
}

const refreshLimits = () => {
  refreshActive.value = !refreshActive.value;
  limitsUpdated();
}
</script>

<template>
  <div
    class="w-full sm:py-4 flex justify-center text-base-priority sm:max-w-[428px] mx-auto"
  >
    <div
      class="bg-layer-1 py-4 pt-4 flex flex-col gap-1 sm:rounded-xl w-full"
    >
      <div class="flex justify-between px-4 mb-2">
        <p class="my-0 font-bold text-lg">{{ $t('responsible-gambling') }}</p>
        <div class="flex">
          <Button
            type="transparent"
            class="mr-2"
            padding="sm"
            icon
            @click="refreshLimits()"
          >
            <ArrowPathIcon :class="refreshActive ? 'rotate-180' : '-rotate-180'" class="w-5 transition-all" />
          </Button>
          <InformationIcon
            class="cursor-pointer mt-1"
            @click="openInfo(tabs.d_value)"
          />
        </div>
      </div>
      <hr class="dark:border-dark-900 border-light-400" />
      <div class="card">
        <Tabs :value="defaultTab" ref="tabs">
          <TabList
            v-if="locksEnabled && limitsEnabled"
            :pt="responsibleGamingTabsPassthrough.tabList"
          >
            <Tab
              v-if="locksEnabled"
              value="Exclusion"
              :pt="responsibleGamingTabsPassthrough.tab"
            >
              {{ $t('exclusion') }}
            </Tab>
            <Tab value="Limits" :pt="responsibleGamingTabsPassthrough.tab">
              {{ $t('limits') }}
            </Tab>
          </TabList>
          <hr v-show="limitsEnabled" class="dark:border-dark-900 border-light-400" />
          <TabPanels>
            <TabPanel
              v-if="locksEnabled"
              value="Exclusion"
            >
              <Exclusion @limitAdded="limitsUpdated" />
            </TabPanel>
            <TabPanel value="Limits">
              <Limits @limitAdded="limitsUpdated" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
