import type { IRestrictionMediator } from '~/interfaces/dto/player/response.ts';
export const restrictionMappingKeys = Object.freeze({
  selfExclusionGamblingProblem: 'self-exclusion-problem',
  selfExclusionFinancialDifficulties: 'self-exclusion-financial-difficulties',
  selfExclusionGamblingBreak: 'self-exclusion-break',
  coolingOff: 'cooling-off',
  dailyWagerLimit: 'daily-wager-limit',
  weeklyWagerLimit: 'weekly-wager-limit',
  monthlyWagerLimit: 'monthly-wager-limit',
  sessionLimit: 'session-limit'
})
export const restrictionMapping: IRestrictionMediator[] = [
  {
    name: "I Have A Gambling Problem.",
    key: restrictionMappingKeys.selfExclusionGamblingProblem,
    mappingCode: "90e133ac-305e-43cf-bc99-3182edee43af",
    threshold: 6,
    thresholdType: 4,
  },
  {
    name: "I Have Financial Difficulties.",
    key: restrictionMappingKeys.selfExclusionFinancialDifficulties,
    mappingCode: "ec196d58-0275-47f4-a4af-58df282ab605",
    threshold: 6,
    thresholdType: 4,
  },
  {
    name: "I Want To Take A Break From Gambling.",
    key: restrictionMappingKeys.selfExclusionGamblingBreak,
    mappingCode: "b05b6c3f-a6e1-4226-b345-8cde72e31d99",
    threshold: 6,
    thresholdType: 4,
  },
  {
    name: "Responsible Gaming (Cooling Off).",
    key: restrictionMappingKeys.coolingOff,
    mappingCode: "7e93f963-c4ba-4898-aa46-2f68eb9bf157",
    threshold: null,
    thresholdType: null,
    durationOptions: {
      days: [
        1,
        2,
        3,
        4,
        5,
        6
      ],
      weeks: [
        1,
        2,
        3
      ],
      months: [
        1,
        2,
        3
      ]
    }
  },
  {
    name: "Responsible Gaming (Daily Wager Limit).",
    key: restrictionMappingKeys.dailyWagerLimit,
    mappingCode: "20d8a2c4-0f22-4ebf-8e26-b34282de4edf",
    translation: 'daily',
    total: 0,
    currentLimit: 0,
    newLimit: 0,
    progress: 0,
    threshold: null,
    thresholdType: 0,
  },
  {
    name: "Responsible Gaming (Weekly Wager Limit).",
    key: restrictionMappingKeys.weeklyWagerLimit,
    mappingCode: "2739ba75-2c37-4955-823c-6d7735f01ed7",
    translation: 'weekly',
    total: 0,
    currentLimit: 0,
    newLimit: 0,
    progress: 0,
    threshold: null,
    thresholdType: 0,
  },
  {
    name: "Responsible Gaming (Monthly Wager Limit).",
    key: restrictionMappingKeys.monthlyWagerLimit,
    mappingCode: "f5efcedf-1d14-466e-931d-b3cb27c68f7b",
    translation: 'monthly',
    total: 0,
    currentLimit: 0,
    newLimit: 0,
    progress: 0,
    threshold: null,
    thresholdType: 0,
  },
  {
    name: "Responsible Gaming (Session Limit).",
    key: restrictionMappingKeys.sessionLimit,
    mappingCode: "2ee899c8-2773-4859-a35e-48b12bda4018",
    threshold: null,
    thresholdType: 1,
    durationOptions: {
      minutes: [
        15,
        30,
        45,
        60
      ],
    }
  }
];

export const rgKeyLookup: Map<string, Partial<IRestrictionMediator>> = new Map(restrictionMapping.map((rm: IRestrictionMediator) => {
  return [rm.key, { ...rm }]
}));
export const rgMappingCodeLookup: Map<string, Partial<IRestrictionMediator>> = new Map(restrictionMapping.map((rm: IRestrictionMediator) => {
  return [rm.mappingCode, { ...rm }]
}));
export const rgNameLookup: Map<string, Partial<IRestrictionMediator>> = new Map(restrictionMapping.map((rm: IRestrictionMediator) => {
  return [rm.name, { ...rm }]
}));