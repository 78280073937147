import type { PassThrough } from '@primevue/core';
import type { SelectButtonPassThroughOptions } from 'primevue/selectbutton';

export const selectButtonPassthrough: PassThrough<SelectButtonPassThroughOptions> =
  {
    root: {
      class: 'flex gap-2',
    },
    pcToggleButton: {
      label: {
        class: 'font-bold',
      },
      root: ({ props }) => ({
        class: [
          'pseudo-button',
          {
            'bg-primary-blue-gradient text-white': props.modelValue,
          },
        ],
      }),
    },
  };
